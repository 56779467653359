import {NgModule} from "@angular/core";
import {Route, RouterModule} from "@angular/router";
import {Roles} from "../../login/api/Roles";
import {AuthGuard} from "../../login/guards/AuthGuard";
import {RegisterComponent} from "../../register/RegisterComponent";

const routes: Route[] = [

  {
    path: "backoffice",
    loadChildren: () => import("../../backoffice/BackofficeModule").then(m => m.BackofficeModule),
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.ADMIN],
    },
  },

  {
    path: "registrera",
    component: RegisterComponent,
  },

  {
    path: "test",
    loadChildren: () => import("../../editor-section/EditorSectionModule").then(m => m.EditorSectionModule),
  },

  {
    path: "epub",
    loadChildren: () => import("../../epub/EpubModule").then(m => m.EpubModule),
  },

  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
  })],
  exports: [RouterModule],
})
export class SynportalenRouterModule {
}
