import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {SynportalenAppModule} from "./app/SynportalenAppModule";
import {environment} from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(SynportalenAppModule, {
    preserveWhitespaces: true,
  })
  .catch(err => console.log(err));
