import {AfterViewInit, Component} from "@angular/core";
import {createDispatchMap, select} from "@ngxs/store";
import {AuthActions} from "../redux/AuthActions";
import {AuthQueries} from "../redux/AuthQueries";
import CheckLoginStatusAction = AuthActions.CheckLoginStatusAction;

@Component({
  selector: "account-menu",
  templateUrl: "AccountMenuComponent.html",
})
export class AccountMenuComponent implements AfterViewInit {

  auth = select(AuthQueries.auth);

  private actions = createDispatchMap({
    CheckLoginStatusAction,
  });

  ngAfterViewInit(): void {
    this.actions.CheckLoginStatusAction();
  }

}
