<ul class="navbar-nav">
  <li class="nav-item"
      dropdown>

    <a class="nav-link dropdown-toggle cursor-pointer"
       dropdownToggle
       aria-controls="dropdown-account">
      <fa-icon [icon]="faUser"></fa-icon>
      {{ whoami.fullname }}
    </a>
    <ul id="dropdown-account"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu">
      <li class="dropdown-header">{{ whoami.username }}</li>

      @if (whoami.roles.includes('ADMIN')) {
        <li role="menuitem">
          <a class="dropdown-item"
             routerLink="/epub/project">
            EPUB-redigeringsverktyg
          </a>
        </li>

        <li role="menuitem">
          <a class="dropdown-item"
             routerLink="/backoffice">
            Backoffice
          </a>
        </li>
      }

      <li class="divider dropdown-divider"></li>

      <li role="menuitem">
        <a class="dropdown-item"
           (click)="logout($event)"
           tabindex="0"
           href="#">
          <fa-icon [icon]="faSignOut"></fa-icon>
          Logga ut
        </a>
      </li>
    </ul>
  </li>
</ul>
