import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {faSignOut} from "@fortawesome/pro-light-svg-icons/faSignOut";
import {faUser} from "@fortawesome/pro-light-svg-icons/faUser";
import {createDispatchMap} from "@ngxs/store";
import {NotificationsFacade} from "../../notifications/NotificationsFacade";
import {IAuthStateModel} from "../api/IAuthStateModel";
import {AuthActions} from "../redux/AuthActions";
import LogoutAction = AuthActions.LogoutAction;

@Component({
  selector: "logged-in-menu",
  templateUrl: "LoggedInMenu.html",
})
export class LoggedInMenu {

  readonly faUser = faUser;
  readonly faSignOut = faSignOut;

  @Input()
  whoami: IAuthStateModel;

  private actions = createDispatchMap({
    LogoutAction,
  });

  constructor(
    private router: Router,
    private notificationsService: NotificationsFacade,
  ) {
  }

  public logout(e: MouseEvent) {
    e.preventDefault();

    this.actions
      .LogoutAction()
      .subscribe(() => {
        this.notificationsService.info("Hej då!", "Välkommen åter");
        this.router.navigate(["/"]);
      });
  }

}
