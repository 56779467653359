<ng-container>
  <div class="btn-group"
       role="group">
    <ng-container>
      <button class="btn btn-primary"
              routerLink="/registrera">
        Registrera
      </button>

      <div dropdown
           [autoClose]="true"
           [insideClick]="true"
           class="btn-group">
        <button class="btn btn-primary dropdown-toggle"
                dropdownToggle
                aria-controls="dropdown-login">
          Logga in
        </button>
        <ul id="dropdown-login"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right mt-1">
          <form class="space-y-4"
                (submit)="login()"
                [formGroup]="form">
            <div class="form-group">
              <label class="form-label"
                     for="username-input">
                Användarnamn
              </label>
              <input class="form-control"
                     id="username-input"
                     formControlName="username"
                     autocomplete="username email">
            </div>
            <div class="form-group">
              <label class="form-label"
                     for="password-input">
                Lösenord
              </label>
              <input type="password"
                     class="form-control"
                     id="password-input"
                     formControlName="password"
                     autocomplete="current-password">
            </div>
            <button
              class="btn btn-success block w-full"
              [ladda]="loading|async"
              [disabled]="(loading|async) || !form.valid"
            >
              Logga in
            </button>
          </form>
        </ul>
      </div>
    </ng-container>
  </div>
</ng-container>
