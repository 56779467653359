import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import {tap} from "rxjs/operators";
import {NewUser, RegisterUserService} from "./RegisterUserService";

export class RegisterUserAction {
  static readonly type = "[Register] user";

  constructor(public user: NewUser) {
  }
}

export interface RegisterStateModel extends NewUser {
  registered: boolean;
}

@State<RegisterStateModel>({
  name: "register",
  defaults: {
    email: null,
    fullname: null,
    password: null,
    registered: false,
  },
})

@Injectable()
export class RegisterState {

  constructor(private registerUserService: RegisterUserService) {
  }

  @Action(RegisterUserAction)
  public register({setState}: StateContext<RegisterStateModel>, {user}: RegisterUserAction) {
    return this.registerUserService.register(user).pipe(
      tap(newUser => setState({...newUser, registered: true})),
    );
  }
}
