import {Component} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {createDispatchMap} from "@ngxs/store";
import {BehaviorSubject} from "rxjs";
import {finalize} from "rxjs/operators";
import {NotificationsFacade} from "../notifications/NotificationsFacade";
import {RegisterUserAction} from "./RegisterState";

@Component({
  selector: 'app-register',
  templateUrl: './RegisterComponent.html',
  styleUrls: ['./RegisterComponent.css'],
  host: {
    class: "p-4",
  },
})
export class RegisterComponent {
  public form: UntypedFormGroup;
  public loading = new BehaviorSubject<boolean>(false);

  private actions = createDispatchMap({
    RegisterUserAction,
  });

  constructor(fb: UntypedFormBuilder, private notificationService: NotificationsFacade) {
    this.form = fb.group({
      fullname: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      teacher: ["", Validators.required],
    });
  }

  register() {
    console.log('register');
    const value = this.form.value;
    this.loading.next(true);
    this.actions
      .RegisterUserAction(value).pipe(
      finalize(() => this.loading.next(false)),
    ).subscribe(
      () => {
        this.notificationService.success("Registrering klar", "Välkommen till Synportalen");

      },
      () => {
        this.notificationService.error("Regstrering misslyckades", "Kontrollera uppgifterna och prova igen");
      },
    );

  }
}
