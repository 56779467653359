<form (submit)="register()"
      [formGroup]="form"
      class="space-y-4">

  <div class="form-group">
    <label class="form-label"
           for="register-name-input">
      Ditt namn
    </label>
    <input type="text"
           class="form-control"
           id="register-name-input"
           formControlName="fullname"
           autocomplete="name">
  </div>

  <div class="form-group">
    <label class="form-label"
           for="register-email-input">
      E-postadress
    </label>
    <input type="email"
           class="form-control"
           id="register-email-input"
           aria-describedby="email-help"
           formControlName="email"
           autocomplete="email">
    <small id="email-help"
           class="form-text text-muted">Din e-postadress blir ditt användarnamn</small>
  </div>

  <div class="form-group form-check">
    <input id="teacher"
           type="checkbox"
           class="form-check-input"
           value="yes"
           formControlName="teacher">
    <label class="form-check-label"
           for="teacher">
      Jag intygar att jag är lärare och har en elev med synnedsättning
    </label>
  </div>

  <button
    class="btn btn-success block w-full"
    [ladda]="loading|async"
    [disabled]="(loading|async) || !form.valid"
  >
    Registrera
  </button>
</form>
