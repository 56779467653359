import {Component, HostBinding} from "@angular/core";

@Component({
  selector: 'app-root',
  templateUrl: './AppRootComponent.html',
  styleUrls: ['./AppRootComponent.css'],
})
export class AppRootComponent {

  @HostBinding("class")
  public get classes() {
    return "flex flex-col";
  }

}
