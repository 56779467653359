import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsRouterPluginModule} from "@ngxs/router-plugin";
import {NgxsModule} from "@ngxs/store";
import {LaddaModule} from 'angular2-ladda';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from "ngx-bootstrap/modal";
import {setTheme} from "ngx-bootstrap/utils";
import {environment} from "../environments/environment";
import {AccountMenuComponent} from "../login/presentation/AccountMenuComponent";
import {LoggedInMenu} from '../login/presentation/LoggedInMenu';
import {LoginComponent} from '../login/presentation/LoginComponent';
import {AuthState} from "../login/redux/AuthState";
import {SecurityService} from "../login/service/SecurityService";
import {NotificationsModule} from "../notifications/NotificationsModule";
import {RegisterComponent} from "../register/RegisterComponent";
import {RegisterState} from "../register/RegisterState";
import {RegisterUserService} from "../register/RegisterUserService";
import {AppRootComponent} from "./presentation/AppRootComponent";
import {StartToolbarComponent} from "./presentation/StartToolbarComponent";
import {TopBarComponent} from './presentation/TopBarComponent';
import {SynportalenRouterModule} from "./routing/SynportalenRouterModule";
import {XhrInterceptor} from "./service/xhrinterceptor";


@NgModule({
  declarations: [
    AppRootComponent,
    LoginComponent,
    RegisterComponent,
    TopBarComponent,
    LoggedInMenu,
    AccountMenuComponent,
    StartToolbarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    LaddaModule.forRoot({
      style: "expand-right",
      spinnerSize: 25,
      spinnerColor: "white",
      spinnerLines: 12,
    }),
    ModalModule.forRoot(),
    NgxsModule.forRoot([
      AuthState,
      RegisterState,
    ], {
      developmentMode: !environment.production, selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NotificationsModule,
    ReactiveFormsModule,
    SynportalenRouterModule,
    FontAwesomeModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true},
    AuthState,
    SecurityService,
    RegisterState,
    RegisterUserService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppRootComponent],
})
export class SynportalenAppModule {

  constructor() {
    // TODO (bootstrap-upgrade) set version for ngx-bootstrap
    setTheme("bs5");
  }

}
