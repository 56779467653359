<nav class="navbar navbar-expand navbar-dark bg-gray-700">
  <div class="container-fluid">

    <div class="flex space-x-2 mr-auto">
      <start-toolbar></start-toolbar>

      <!-- Additional named outlets must be wrapped in a div, otherwise space-x-* will be applied to both
                 the outlet and the component which will be created as sibling to outlet -->
      <div>
        <!-- An optional menu which applies to the selected section -->
        <router-outlet name="menu"></router-outlet>
      </div>
    </div>

    <account-menu class="ml-auto"></account-menu>
  </div>
</nav>
