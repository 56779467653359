import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

export interface NewUser {
  fullname: string;
  email: string;
  password: string;
}

@Injectable()
export class RegisterUserService {
  constructor(private http: HttpClient) {
  }

  public register(newUser: NewUser): Observable<NewUser> {
    return this.http.post<NewUser>("/api/register", newUser);
  }
}
