import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgxSnotifireModule, SnotifireConfig, SnotifireService, ToastDefaults} from "ngx-snotifire";
import {NotificationsFacade} from "./NotificationsFacade";


const snotifireConfigOverrides: Partial<SnotifireConfig> = {
  timeout: 7500,
  // XXX dts shows position is of type SnotificationPositionType, but it works only if setting a string of the
  //  same name as the enum name (not the actual value!)
  position: <never>"CENTER_TOP",
  pauseOnHover: true,
  showProgressBar: false,
  bodyMaxLength: 400,
  titleMaxLength: 400,
};

const snotifireToastOptions = {
  ...ToastDefaults,
  global: {
    ...ToastDefaults.global,
    newOnTop: true,
  },
  snotifireConfig: {
    ...ToastDefaults.snotifireConfig,
    ...snotifireConfigOverrides,
  },
};

@NgModule({
  imports: [
    CommonModule,
    NgxSnotifireModule,
  ],
  providers: [
    {
      provide: "snotifireConfig",
      useValue: snotifireToastOptions,
    },
    SnotifireService,
    NotificationsFacade,
  ],
  exports: [
    NgxSnotifireModule,
  ],
})
export class NotificationsModule {
}
