import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {createDispatchMap} from "@ngxs/store";
import {BehaviorSubject} from "rxjs";
import {finalize} from "rxjs/operators";
import {NotificationsFacade} from "../../notifications/NotificationsFacade";
import {AuthActions} from "../redux/AuthActions";
import CheckLoginStatusAction = AuthActions.CheckLoginStatusAction;
import LoginAction = AuthActions.LoginAction;

@Component({
  selector: 'app-login',
  templateUrl: './LoginComponent.html',
  styleUrls: ['./LoginComponent.css'],
})
export class LoginComponent {

  public form: UntypedFormGroup;
  public loading = new BehaviorSubject<boolean>(false);

  private actions = createDispatchMap({
    CheckLoginStatusAction,
    LoginAction,
  });

  constructor(
    fb: UntypedFormBuilder,
    private notificationsService: NotificationsFacade,
  ) {
    this.form = fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  login() {
    const value = this.form.value;
    this.loading.next(true);
    this.actions
      .LoginAction(value).pipe(
      finalize(() => this.loading.next(false)),
    )
      .subscribe({
          next: () => {
            this.notificationsService.success("Hej!", "Välkommen in");
            setTimeout(() => {
              if (window["PasswordCredential"]) {
                console.log("PasswordCredential detected");
                const auth = {
                  id: value.username,
                  password: value.password,
                };
                const credentials = new window["PasswordCredential"](auth);
                navigator["credentials"].store(credentials);
              } else {
                history.replaceState(history.state, "Synportalen - inloggad");
              }
            });
          },
          error: () => this.notificationsService.error("Inloggning nekad", "Kontrollera inloggningsuppgifter"),
        },
      );
  }

}
