<div class="btn-toolbar space-x-2"
     role="toolbar"
     aria-label="Verktygsfält för att navigera bland olika sektioner">

  <div class="btn-group"
       role="group">
    <a routerLink="/epub"
       class="btn btn-light">
      Projektlista
    </a>
    <a routerLink="/epub/archive"
       class="btn btn-light">
      Arkiv
    </a>
  </div>

</div>
